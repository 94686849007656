var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "mainVisual",
    staticClass: "main-visual"
  }, [_c('div', {
    staticClass: "main-visual__bg"
  }), _c('v-container', [_c('v-row', {
    staticClass: "fixed-contents",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "fixed-contents__left",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "main-visual__inner fixed-contents__left__inner",
    staticStyle: {
      "background-image": "url(/images/main/visual-bg-mo.jpg)"
    }
  }, [_c('v-row', {
    staticClass: "main-visual__center-image w-100",
    attrs: {
      "justify": "end",
      "justify-md": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "5",
      "md": "4"
    }
  }, [_c('v-img', {
    attrs: {
      "data-aos": "fade-left",
      "data-aos-delay": "900",
      "src": "/images/main/visual-img.png",
      "max-width": "464",
      "aspect-ratio": 464 / 820
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "main-visual__info"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5",
      "xl": "6"
    }
  }, [_c('tit-wrap-primary', {
    staticClass: "pb-30px pb-md-40px pb-lg-60px",
    attrs: {
      "dark": "",
      "titFit": ""
    },
    scopedSlots: _vm._u([{
      key: "TitAdd",
      fn: function () {
        return [_vm._v("Choi Yuna")];
      },
      proxy: true
    }])
  }, [_c('icon-rounded-object', {
    attrs: {
      "color": "white--text"
    }
  }), _c('span', {
    staticClass: "font-weight-regular"
  }, [_vm._v("이혼전문변호사 ")]), _vm._v(" 최유나 ")], 1), _c('div', {
    staticClass: "pl-16px pl-md-24px border-l",
    staticStyle: {
      "border-color": "rgba(255, 255, 255, .2) !important"
    },
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "500"
    }
  }, [_c('txt', {
    staticClass: "txt--sm secondary--text text--lighten-4"
  }, _vm._l(_vm.info, function (detail, index) {
    return _c('p', {
      key: index,
      class: index !== 0 ? 'mt-12px mt-md-24px' : ''
    }, [_c('span', {
      domProps: {
        "innerHTML": _vm._s(detail)
      }
    })]);
  }), 0)], 1)], 1)], 1)], 1)]), _c('v-col', {
    staticClass: "fixed-contents__right",
    attrs: {
      "cols": "12",
      "md": "4",
      "offset-md": "8"
    }
  }, [_c('div', {
    staticClass: "main-visual__inner",
    staticStyle: {
      "background-image": "url(/images/main/visual-bg-mo2.jpg)"
    }
  }, [_c('v-img', {
    staticClass: "main-visual__txt-bg w-100 d-md-none",
    attrs: {
      "src": "/images/main/visual-bg-mo3.svg",
      "contain": "",
      "max-width": "1840",
      "aspect-ratio": 1840 / 480
    }
  }), _c('v-row', _vm._l(_vm.media, function (item) {
    return _c('v-col', {
      key: item.title,
      attrs: {
        "cols": "6",
        "md": "12"
      }
    }, [_c('div', {
      staticClass: "d-md-flex justify-md-end"
    }, [_c('div', {
      staticClass: "w-100",
      staticStyle: {
        "max-width": "408px"
      }
    }, [_c('v-card', {
      staticClass: "w-100 transparent",
      attrs: {
        "rounded": "sm"
      }
    }, [_c('v-img', {
      staticClass: "w-100",
      attrs: {
        "src": item.image,
        "aspect-ratio": 408 / 228,
        "eager": ""
      }
    })], 1), _c('p', {
      staticClass: "w-100 font-size-14 white--text mt-4px mt-md-8px"
    }, [_vm._v(_vm._s(item.subTitle))])], 1)])]);
  }), 1)], 1)])], 1)], 1), _c('u-scroll-ani', {
    staticClass: "d-none d-xl-flex"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }