export const media = [
    {
        title : "[#티전드] 로켓 이혼 시켜드립니다🚀 이혼 전문 변호사가 직접 겪은 화가 절로 나는 기상천외한 이혼 사건들🔥 | #유퀴즈온더블럭 #Diggle",
        subTitle : "TVN 유퀴즈 제헌절특집 출연",
        image : "/images/media/media-img.jpg",
        video : "-Z5pKRsHDPA",
    },
    {
        title : "이혼 베테랑 최유나 변호사, 돌싱포맨에 찾아온 끝판왕 전문가★ #신발벗고돌싱포맨 #InvitationfromBacheloragains #SBSenter",
        subTitle : "SBS 돌싱포맨 출연",
        image : "/images/media/media-img2.jpg",
        video : "M4ZGc2hGd4c",
    },
    {
        title : "이혼하지 않으려면 알아야 할 소통의 기술 | 최유나 이혼 전문 변호사, 인스타툰 '메리지 레드' 작가 | 결혼 이혼 부부 | 세바시 1274회",
        subTitle : "세바시 강연 200만뷰",
        image : "/images/media/media-img3.jpg",
        video : "dGgtfT-ZkaI",
    },
    {
        title : "I'll Lead you to victory | Lawyer | Park Sung-woong | Legend | Workman 2",
        subTitle : "워크맨2 출연",
        image : "/images/media/media-img4.jpg",
        video : "Pcd472TbuFQ",
    },
    {
        title : "우리는 정말 서로를 싫어해서 헤어지는 걸까? 관계는 주로 어떻게 끝나는가 | 11년차 이혼전문변호사 최유나 | 이혼 이별 인간관계",
        subTitle : "MBC 하우투 출연1",
        image : "/images/media/media-img5.jpg",
        video : "7UByYqaBwBs",
    },
    {
        title : "’이혼은 인생 실패? 놀림거리?’ - 최유나 변호사가 바라본 이혼 이야기 [프란 피플 P:ple Ep.5]",
        subTitle : "프란 피플 출연",
        image : "/images/media/media-img6.jpg",
        video : "bhw_-qsbXDs",
    },
    {
        title : "사랑하는 사람과 오래 잘 사는 방법 | 최유나 이혼 전문 변호사, '혼자와 함께 사이' 저자 w/ 구범준 PD | 세바시 나머지45분 ep.32 [책이벤트]",
        subTitle : "세바시 강연",
        image : "/images/media/media-img7.jpg",
        video : "N0NP8znMkr0",
    },
    {
        title : "아파트를 도박으로 날려도 참았다고?...이혼전문변호사가 말하는 '좋은 이혼'?",
        subTitle : "뉴스1TV 출연",
        image : "/images/media/media-img8.jpg",
        video : "1iWT5TADPV8",
    },
    {
        title : "'관계에서 나 자신을 지켜 낼 5가지 방법' / 최유나 변호사 11년차 이혼전문 / MBC강원영동 220715",
        subTitle : "MBC 하우투 출연2",
        image : "/images/media/media-img9.jpg",
        video : "-igXM_N-3K4",
    },
];