<template>     
    <div class="media-contents">
        <swiper data-aos="fade-up" data-aos-delay="100" ref="swiperMedia" :options="swiperOptionMedia">
            <swiper-slide v-for="item in media" :key="item.title" class="swiper swiper--media">
                <v-card tile class="media-card v-card--none-active-bg w-100 transparent" @click="open(item.video)">
                    <v-responsive :aspect-ratio="708 / 398" class="media-card__image image-card w-100" :style="'background-image : url('+item.image+')'" />
                    <v-btn color="primary lighten-1" class="media-card__btn v-btn--none-active-bg"><icon-plus /></v-btn>
                </v-card>
            </swiper-slide>
        </swiper>
        <v-row data-aos="fade-up" data-aos-delay="100" no-gutters justify="end" align="center" class="media-contents__control">
            <v-col cols="auto">
                <v-btn icon color="grey lighten-1" class="swiper-btn-prev v-btn--text-fit"><icon-arrow-primary direction="left" /></v-btn>
            </v-col>
            <v-col cols="auto">
                <div class="swiper-pagination"></div>
            </v-col>
            <v-col cols="auto">
                <v-btn icon color="grey lighten-1" class="swiper-btn-next v-btn--text-fit"><icon-arrow-primary /></v-btn>
            </v-col>
        </v-row>
        <v-bottom-sheet v-model="show" eager fullscreen content-class="mw-100 h-100" max-width="100%" width="100%">
            <v-btn large absolute top right icon text color="white" @click="close">
                <icon-plus type="close" size="xx-large" />
            </v-btn>
            <v-responsive max-width="1032" :aspect-ratio="1032 / 580">
                <iframe :src="'https://www.youtube.com/embed/'+selectedVideo" class="w-100 h-100 d-block" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen />
            </v-responsive>
        </v-bottom-sheet>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { media } from "@/assets/data/media.js";

import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";
import IconPlus from "@/components/publish/styles/icons/icon-plus.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,

        IconArrowPrimary,
        IconPlus,
        Tit,
        Txt,
    },
    data() {
        return {
            media,
            show: false,
            selectedVideo: "",
            swiperOptionMedia: {
                loop: false,
                slidesPerView: 1,
                spaceBetween: 12,
                speed: 500,
                navigation: {
                    nextEl: ".media-contents .swiper-btn-next",
                    prevEl: ".media-contents .swiper-btn-prev",
                },
                breakpoints: {
                    1024:{
                        spaceBetween: 60,
                    },
                },
                pagination: {
                    el: '.media-contents .swiper-pagination',
                    type: "fraction",
                },
            },
        };
    },
    methods:{
        open(video){
            this.show = true;            
            this.selectedVideo = video;
        },
        close(){
            this.show = false;
            this.selectedVideo = "";
        }
    }
}
</script>

<style lang="scss" scoped>

::v-deep{
    .v-bottom-sheet{
        background-color: rgba(0, 0, 0, .8) !important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px;
    }
}
    
.media-contents{
    position: relative;
    ::v-deep{
        .swiper-container{
            overflow: visible;
            margin: 0;
        }
        .swiper-slide{
            transition: 0.15s ease-out;
            opacity: 1;
            visibility: visible;
            max-height: 100%;
            max-width: 100%;
        }
        .swiper-button-disabled{
            opacity: .3;
        }
        .swiper-slide:not(.swiper-slide-active):not(.swiper-slide-next){
            opacity: 0;
            visibility: hidden;
        }
        .swiper-slide:not(.swiper-slide-active){
            cursor: default;
            pointer-events: none;
        }
        .swiper-pagination{
            position: relative;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            font-family: var(--font-secondary);
            font-size: 1.6rem;
            font-weight: 700;
            line-height: 1;
            color: #fff;
            .swiper-pagination-current{
                display: block;
                width: 36px;
                text-align: right;
                font-size: 3rem;
                background: linear-gradient(180deg, #B18A00 0%, #FFEA9F 50%, #B18A00 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                padding-right: 4px;
            }
            .swiper-pagination-total{
                display: block;
                width: 28px;
                text-align: left;
                padding-left: 4px;
            }
        }
    }
    &__control{
        margin-top: 16px !important;
    }
}

.media-card{
    position: relative;
    padding-bottom: 10px;
    &__btn{
        position: absolute;
        left: -10px;
        bottom: 0;
        min-width: 0 !important;
        padding: 0 !important;
        width: 50px !important;
        height: 50px !important;
        border-bottom-right-radius: 16px !important;
        transition: 0.15s ease-out;
        opacity: 0;
        visibility: hidden;
        &.primary{
            color: #d9d9d9 !important;
        }
    }
    &__image{
        max-width: 100%;
        height: 100%;
        transition: 0.25s ease-out;
        position: relative;
        &::after{
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            background-color: rgba(0, 0, 0, .7);
            opacity: 0;
            visibility: hidden;
            transition: 0.25s ease-out;
        }
    }
}
.swiper-slide.swiper-slide-active{
    .media-card__btn{
        opacity: 1;
        visibility: visible;
        transition: 0.15s all ease-out, 0.25s 0.25s opacity ease-out, 0.25s 0.25s visibility ease-out;
    }
}

@media (min-width:576px){
}
@media (min-width:768px){
    .media-card{
        padding-bottom: 20px;
        &__btn{
            left: -20px;
            width: 80px !important;
            height: 80px !important;
            border-bottom-right-radius: 24px !important;
        }
    }
    .media-contents{
        ::v-deep{
            .swiper-pagination{
                font-size: 1.8rem;
                .swiper-pagination-current{
                    width: 50px;
                    font-size: 4.8rem;
                }
                .swiper-pagination-total{
                    width: 34px;
                }
            }
        }
        &__control{
            margin-top: 30px !important;
        }
    }
    ::v-deep{
        .v-bottom-sheet{
            .v-btn--absolute.v-btn--top.v-btn--right{
                top: 58px;
                right: 18px;
            }
        }
    }
}
@media (min-width:1024px){
    .media-card:hover{
        .media-card__btn{
            background-color: var(--v-primary-base) !important;
        }
    }
}
@media (min-width:1200px){    
    .media-contents{
        ::v-deep{
            .swiper-container{
                width: 708px;
            }
            .swiper-slide{
                height: 418px;
            }
            .swiper-slide:not(.swiper-slide-active){
                .media-card__image{
                    max-width: 494px;
                    max-height: 278px;
                }
                .media-card__image::after{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}
@media (min-width:1544px){    
    .media-contents{
        &__control{
            position: absolute;
            z-index: 5;
            bottom: 20px;
            left: 768px;
            margin-bottom: 0 !important;
        }
    }
}
</style>