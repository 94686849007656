<template>
    <div class="main-visual" ref="mainVisual">
        <div class="main-visual__bg"></div>
        <v-container>
            <v-row no-gutters class="fixed-contents">
                 <v-col cols="12" class="fixed-contents__left">
                    <div class="main-visual__inner fixed-contents__left__inner" style="background-image: url(/images/main/visual-bg-mo.jpg);">
                        <v-row justify="end" justify-md="center" class="main-visual__center-image w-100">
                            <v-col cols="5" md="4">
                                <v-img data-aos="fade-left" data-aos-delay="900" src="/images/main/visual-img.png" max-width="464" :aspect-ratio="464 / 820" />
                            </v-col>
                        </v-row>
                        <v-row class="main-visual__info">
                            <v-col cols="12" md="5" xl="6">
                                <tit-wrap-primary dark titFit class="pb-30px pb-md-40px pb-lg-60px">
                                    
                                    <icon-rounded-object color="white--text" />
                                    <span class="font-weight-regular">이혼전문변호사 </span>
                                    최유나
                                    <template #TitAdd>Choi Yuna</template>
                                </tit-wrap-primary>
                                <div data-aos="fade-up" data-aos-delay="500" class="pl-16px pl-md-24px border-l" style="border-color: rgba(255, 255, 255, .2) !important;">
                                    <txt class="txt--sm secondary--text text--lighten-4">
                                        <p v-for="(detail, index) in info" :key="index" :class="index !== 0 ? 'mt-12px mt-md-24px' : ''">
                                            <span v-html="detail"></span>
                                        </p>
                                    </txt>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                 </v-col>
                 <v-col cols="12" md="4" offset-md="8" class="fixed-contents__right">
                    <div class="main-visual__inner" style="background-image: url(/images/main/visual-bg-mo2.jpg);">
                        <v-img src="/images/main/visual-bg-mo3.svg" contain max-width="1840" :aspect-ratio="1840 / 480" class="main-visual__txt-bg w-100 d-md-none" />
                        <v-row>
                            <v-col v-for="item in media" :key="item.title" cols="6" md="12">
                                <div class="d-md-flex justify-md-end">
                                    <div class="w-100" style="max-width: 408px;">
                                        <v-card rounded="sm" class="w-100 transparent">
                                            <v-img :src="item.image" :aspect-ratio="408 / 228" eager class="w-100"/>
                                        </v-card>
                                        <p class="w-100 font-size-14 white--text mt-4px mt-md-8px">{{item.subTitle}}</p>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                 </v-col>
            </v-row>
        </v-container>
        <u-scroll-ani class="d-none d-xl-flex"/>
    </div>
</template>

<script>
import { media } from "@/assets/data/media.js";

import UScrollAni from "@/components/publish/styles/scroll/u-scroll-ani.vue";
import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";
import IconRoundedObject from "@/components/publish/styles/icons/icon-rounded-object.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    components: {
        UScrollAni,
        TitWrapPrimary,
        IconRoundedObject,
        Tit,
        Txt,
    },
    props: {},
    data() {
        return {
            media,
            scrollFixedData: null,
            window: {
                width: 0,
                height: 0,
            },
            info : [
                "이혼전문변호사 (3,000건 이상 진행)",
                "25만명 구독 이혼툰 <메리지레드> 연재",
                "베스트셀러 <혼자와함께사이>, <우리이만헤어져요>",
                "TVN 유퀴즈 제헌절특집 출연",
                "SBS 돌싱포맨 KBS 아침마당 등 다수 방송출연",
                "네이버연애결혼,법률판 (전)자문",
                "매일경제(전)칼럼리스트",
                "세바시강연 200만뷰",
                "그외 CBS시사자키, 주간동아, 여성동아, <br class='d-md-none' /> 한국일보, 조선일보, '워크맨' <br />출연 외 다수 유튜브 출연 등",
            ],
        };
    },
    mounted() {
        this.initPcEvent();
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
        this.destroyPcEvent();
    },
    methods: {
        handleResize: function() {
            if (this.isDesktop) {
                window.addEventListener('scroll', this.onScrollFixed);
                const pageSectionFixed = document.querySelector('.main-visual');
                if (pageSectionFixed !== null) {
                    pageSectionFixed.classList.add('active');
                    pageSectionFixed.classList.remove('bottom');
                }
            } else {
                window.removeEventListener('scroll', this.onScrollFixed);
                const pageSectionFixed = document.querySelector('.main-visual');
                var fixedContentsLeft = document.querySelector('.fixed-contents__left');
                if (pageSectionFixed !== null) {
                    pageSectionFixed.classList.remove('active');
                    pageSectionFixed.classList.remove('bottom');
                    fixedContentsLeft.style = '';
                }
            }
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },

        initPcEvent: function() {
            if (this.isDesktop) {
                window.addEventListener('scroll', this.onScrollFixed);
            }
        },

        destroyPcEvent: function() {
            if (this.isDesktop) {
                window.removeEventListener('scroll', this.onScrollFixed);
            }
        },

        onScrollFixed: function() {
            var scrollTop = window.scrollY || document.documentElement.scrollTop;
            var pageSectionFixed = document.querySelector('.main-visual');
            var fixedContentsLeft = document.querySelector('.fixed-contents__left');
            if (pageSectionFixed !== null) {
                var pageSectionFixedTop = pageSectionFixed.offsetTop;
                var pageSectionFixedHeight = pageSectionFixed.clientHeight;
                var fixedContentsLeftHeight = fixedContentsLeft.clientHeight;
                var pageSectionFixedBottom = pageSectionFixedTop + pageSectionFixedHeight - fixedContentsLeftHeight;
                var fixedContentsLeftTranslate = pageSectionFixedHeight - fixedContentsLeftHeight;
                if (scrollTop > pageSectionFixedTop) {
                    pageSectionFixed.classList.add('active');
                } else {
                    pageSectionFixed.classList.remove('active');
                }
                if (scrollTop > pageSectionFixedBottom) {
                    pageSectionFixed.classList.add('bottom');
                    fixedContentsLeft.style.transform = 'translate(0px,' + fixedContentsLeftTranslate + 'px)';
                } else {
                    pageSectionFixed.classList.remove('bottom');
                    fixedContentsLeft.style = '';
                }
            }
        },
    },
    computed: {
        isDesktop: function() {
            return this.window.width >= 768;
        },
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
        if (!this.isDesktop) {
            window.removeEventListener('scroll', this.onScrollFixed);
        }
    },
    watch: {
        isDesktop: function(newValue) {
            if (newValue) {
                this.initPcEvent();
            } else {
                this.destroyPcEvent();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.main-visual{     
    position: relative;    
    > .container{
        max-width: var(--container-lg);
        padding: 0;
    }
    &__inner{
        position: relative;
        padding: var(--page-section-padding-y) var(--container-gutter);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    &__center-image{
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
    }
    &__info{
        position: relative;
        .icon-rounded-object{
            left: unset;
            right: 16px;
            top: calc(50% - 50px);
            opacity: .2;
        }
    }
    &__txt-bg{
        position: absolute;
        bottom: 0;
        left: 0;
    }
}
.fixed-contents__left{
    &__inner{
        position: relative;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .main-visual{       
        > .container{
            max-width: calc(var(--container-lg) + var(--container-gutter)*2);
        }
        &__inner{
            background-image: none !important;
        }
        &__bg{
            width: 100%;
            height: 100vh;
            top: 0;
            left: 0;
            position: absolute;
            background-image: url(/images/main/visual-bg.jpg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            z-index: -2;
            display: flex;
        }
        &__info{
            position: relative;
            .icon-rounded-object{
                right: 38px;
                top: calc(50% - 90px);
            }
        }
        .scroll-ani{
            position: fixed;
        }
    }
    .fixed-contents__left{
        position:absolute;
        top:0;
        left: 0;
        z-index: -1;
        width: 100%;
        &__inner{
            width: 100%;
            height: 100vh;
            display: flex;
            align-items: center;
            padding: 0 var(--container-gutter);
            max-width: calc(var(--container-lg) + var(--container-gutter)*2);
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .fixed-contents__right{
        position: relative;
        z-index: 2;
    }
    .main-visual.active .fixed-contents__left{
        position: fixed;
    }
    .main-visual.active:not(.bottom) .main-visual__bg{
        position: fixed;
    }
    .main-visual.bottom .main-visual__bg{
        top: unset;
        bottom: 0;
    }
    .main-visual.bottom .fixed-contents__left{
        position: absolute !important;
        top:auto !important;
        left:0 !important;
    }
    .main-visual.bottom .scroll-ani{
        position: absolute;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
    .main-visual.active:not(.bottom) .fixed-contents__left{
        padding-right: var(--header-width) !important;
    }
    .main-visual.active:not(.bottom) .main-visual__bg{
        width: calc(100% - var(--header-width));
    }
}
@media (min-width:1544px){
    .main-visual.active .fixed-contents__left{
        padding-right: 0 !important;
    }
}
</style>
